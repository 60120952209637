import './App.css';


const DirectorsUpdate = () => {

    return (
        <>
            <div className='h-20'></div>
            <div className='heading-rounded m-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'> Director’s Update</div>
            <div className='container px-6 xl:px-0 xl:w-4/5 2xl:container mx-auto py-20 min-h-screen'>
                <div className="fw-col-xs-12 ">
                <div className="text_block">
                        <p className='agm mt-10'>
                            <strong>
                                Appointment of Directors
                            </strong>
                        </p>
                        <ul className='list-disc mt-2 pl-5 mb-14'>
                            <li className='agm_27_2021_li'>
                            Mr. Naveen Jain is appointed as Nominee Director of Florintree Evolution Private Limited  on the Board of IARC.
                            </li>
                            <li className='agm_27_2021_li'>
                                Mr. Vikas Nanda is re - appointed as an Independent Director on the Board of IARC for a term of five years.
                            </li>
                            <li className='agm_27_2021_li'>
                                Mr. Praveen Kadle is appointed as an Independent Director on the Board of IARC for a term of five years.
                            </li>
                            <li className='agm_27_2021_li'>
                                Mr. Neeraj I. Mohan is re - appointed as Managing Director & CEO on the Board of IARC for a term of two years.
                            </li>
                            <li className='agm_27_2021_li'>
                                Mr. Jayanta Kumar Banerjee is appointed as Nominee Director of Florintree Evolution Private Limited on the Board of IARC. 
                            </li>
                            <li className='agm_27_2021_li'>
                                Ms. Sonal Shrivastava is appointed as an Independent Director on the Board of IARC for a term of five years.
                            </li>
                            <li className='agm_27_2021_li'>
                                Mr. Anjani Kumar is appointed as an Independent Director on the Board of IARC for a term of five years.
                            </li>
                        </ul>
                    </div>
                    <div className="text_block">
                        <p className='agm mt-10'>
                            <strong>
                                Resignation of Directors
                            </strong>
                        </p>
                        <ul className='list-disc mt-2 pl-5 mb-14'>
                            <li className='agm_27_2021_li'>
                            Mr. Himanshu Dodeja, Nominee Director of Blackstone India ARC SG FDI Holding (NQ) Co. Pte. Ltd. resigned as Director w.e.f. May 15, 2024.
                            </li>
                            <li className='agm_27_2021_li'>
                                Mr. Vikas Nanda, Independent Director, resigned as a Director w.e.f. 31st March 2023.
                            </li>
                            <li className='agm_27_2021_li'>
                                Mr. Praveen Kadle, Nominee Director of Tata Capital Financial Services Limited, resigned as a Director w.e.f. 31st March 2023.
                            </li>
                            <li className='agm_27_2021_li'>
                                Mr. Menes Ong Chee Jr, Nominee Director of Blackstone India ARC SG FDI Holding (NQ) Co. Pte Ltd., resigned as a Director w.e.f. 10th April 2023.
                            </li>
                            <li className='agm_27_2021_li'>
                                Mr. Nikhil Goyal, Alternate Director to Mr. Menes Chee, Nominee Director of Blackstone India ARC SG FDI Holding (NQ) Co. Pte Ltd., resigned as a Director w.e.f. 10th April 2023.
                            </li>
                            <li className='agm_27_2021_li'>
                                Mr. Luv Parikh, Nominee Director of Blackstone Group, resigned as a Director w.e.f. 31st May 2021.
                            </li>

                            <li className='agm_27_2021_li'>
                                Mr. Kishore Moorjani, Nominee Director of Blackstone Group, resigned as a Director w.e.f. 30th Jun 2021.
                            </li>

                            <li className='agm_27_2021_li'>
                                Mr. Anurag Das, Managing Director & CEO, resigned as a Managing Director & CEO w.e.f. 13th May 2022
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DirectorsUpdate;